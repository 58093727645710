import React from "react";

import { Typography, Grid } from "@mui/material";

import ProjectPage from "../../components/project-page";
import ThumbnailImage from "../../components/thumbnail";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query SamplingProfilerQuery {
    image1: file(relativePath: { eq: "projects/profiler/profiler1.png" }) {
      ...projectThumbnailImage
    }

    image2: file(relativePath: { eq: "projects/profiler/profiler2.png" }) {
      ...projectThumbnailImage
    }

    image3: file(relativePath: { eq: "projects/profiler/profiler3.png" }) {
      ...projectThumbnailImage
    }
  }
`;

const SamplingProfilerPage: React.FC = () => {
  const projectName = `Sampling Profiler`;
  const data = useStaticQuery(query);

  return (
    <ProjectPage projectName={projectName}>
      <Typography variant="body1" component="p">
        This started off being a small project for one of my classes at DigiPen.
        Now, this project has turned into a useful sampling profiler. The
        profiler is a small tool that I wrote in C++ CLI and mixes with C# dlls.
        This is more of a personal project to have fun and make something
        useful.
      </Typography>
      <Typography variant="h5" component="h2">
        Screenshots
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image1} alt="Screenshot 1" />
        </Grid>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image2} alt="Screenshot 2" />
        </Grid>
        <Grid item xs={4}>
          <ThumbnailImage file={data.image3} alt="Screenshot 3" />
        </Grid>
      </Grid>
    </ProjectPage>
  );
};

export default SamplingProfilerPage;
